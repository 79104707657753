import Body1 from "./images/type_1/body.png";
import Hands1 from "./images/type_1/hands.png";
import Body2 from "./images/type_2/body.png";
import Hands2 from "./images/type_2/hands.png";
import Body3 from "./images/type_3/body.png";
import Hands3 from "./images/type_3/hands.png";
import Body4 from "./images/type_4/body.png";
import Hands4 from "./images/type_4/hands.png";
import Background1 from "./images/bg1.png";
import Background2 from "./images/bg2.png";
import Background3 from "./images/bg3.png";
// import Background4 from "./images/bg4.png";

const canvas = document.getElementById("renderer") as HTMLCanvasElement;
const ctx = canvas.getContext("2d");

if (!ctx) {
  throw new Error("Canvas context can't be null");
}

let uploadedImage: string | undefined = undefined;
let uploadedWidth: number | undefined = undefined;
let uploadedHeight: number | undefined = undefined;
let offsetX = 0;
let offsetY = 0;
let degree = 0;
let scale = 1;
let border = 0;
let types = {
  1: [Body1, Hands1],
  2: [Body2, Hands2],
  3: [Body3, Hands3],
  4: [Body4, Hands4]
};
let backgrounds = {
  1: Background1,
  2: Background2,
  3: Background3
  // 4: Background4,
}

// event handlers
function handleInput(id: string, eventName: string, handler: (e: any) => void) {
  const elem = document.getElementById(id);
  if (!elem) {
    throw new Error(`${id} must be existed`);
  }
  elem.addEventListener(eventName, handler, false);
}

const uploader = document.getElementById('uploader');
if (uploader && (('draggable' in uploader) || ('ondragstart' in uploader && 'ondrop' in uploader)) && 'FormData' in window && 'FileReader' in window) {
  const uploadButton = document.getElementById('upload-button')
  uploader.addEventListener('dragover', () => {
    uploadButton && uploadButton.classList.add('dragging');
  });
  uploader.addEventListener('dragleave', () => {
    uploadButton && uploadButton.classList.remove('dragging');
  });
  uploader.addEventListener('drop', () => {
    uploadButton && uploadButton.classList.remove('dragging');
  });
}
// register uploader inputcare-
handleInput("uploader", "change", (e: any) => {
  const reader = new FileReader();
  reader.onload = function (event) {
    if (!ctx) return;

    render(ctx, () => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        if (!event.target) {
          reject();
          return;
        }
        img.onload = async function () {
          if (!event.target) return;
          await renderImage(event.target.result, img.width / 2, img.height / 2, 0, 0, 0);
          uploadedImage = event.target.result;
          uploadedWidth = img.width / 2;
          uploadedHeight = img.height / 2;
          resolve();
        };
        img.src = event.target.result;
      });
    });
  };
  reader.readAsDataURL(e.target.files[0]);
});

// handle controller-inputs
function isUserUploaded() {
  return uploadedImage && uploadedWidth !== undefined && uploadedHeight !== undefined && ctx;
}
handleInput("x-offset-input", "input", (e: any) => {
  if (!isUserUploaded()) return;

  offsetX = +e.target.value;

  render(ctx, () => {
    return new Promise(async (resolve, _) => {
      if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
      await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree, border);
      resolve();
    });
  });
});
handleInput("y-offset-input", "input", (e: any) => {
  if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
  if (!ctx) return;

  offsetY = +e.target.value;

  render(ctx, () => {
    return new Promise(async (resolve, _) => {
      if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
      await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree, border);
      resolve();
    });
  });
});
handleInput("rotate-input", "input", (e: any) => {
  if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
  if (!ctx) return;

  degree = +e.target.value;

  render(ctx, () => {
    return new Promise(async (resolve, _) => {
      if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
      await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree, border);
      resolve();
    });
  });
});
handleInput("scale-input", "input", (e: any) => {
  if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
  if (!ctx) return;

  scale = +e.target.value;

  render(ctx, () => {
    return new Promise(async (resolve, _) => {
      if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
      await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree);
      resolve();
    });
  });
});
handleInput("border-width", "input", (e: any) => {
  if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
  if (!ctx) return;

  border = +e.target.value;

  render(ctx, () => {
    return new Promise(async (resolve, _) => {
      if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
      await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree, border);
      resolve();
    });
  });
});
handleInput("icon-size", "input", (e: any) => {
  simpleRender();
});

var iconElements = document.querySelectorAll("input[name=icon-type]");
Array.prototype.forEach.call(iconElements, function(el, i){
  el.addEventListener("change", function (){
    simpleRender();
  });
})

var backgroundElements = document.querySelectorAll("input[name=background-type]");
Array.prototype.forEach.call(backgroundElements, function(el, i){
  el.addEventListener("change", function (){
    simpleRender();
  });
})

function simpleRender() {
  if (!ctx) return;
  if (!uploadedImage || !uploadedHeight || !uploadedWidth) {
    render(ctx, null);
  } else {
    render(ctx, () => {
      return new Promise(async (resolve, _) => {
        if (!uploadedImage || !uploadedHeight || !uploadedWidth) return;
        await renderImage(uploadedImage, uploadedWidth * scale, uploadedHeight * scale, offsetX, offsetY, degree, border);
        resolve();
      });
    });
  }
}


function download() {
  const download = document.getElementById("download");
  const image = canvas.toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  if (!download) return;
  download.setAttribute("href", image);

  // store file in firebase storage
  if (uploadedImage) {
    canvas.toBlob(function (blob) {
      var image = new Image();
      image.src = blob;

      const today = new Date();
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      const dateTime = date + '--' + time + '--' + today.getTime();

      firebase.storage().ref().child(`images/${dateTime}.png`).put(blob);
    });
  }
}
// @ts-ignore
window.download = download;

function drawImage(img: HTMLImageElement, width: number, height: number, offsetX: number, offsetY: number, degree: number, border: number): void {
  if (!ctx) return;

  ctx.save();
  ctx.translate(width / 2 + offsetX + (canvas.width / 2 - width / 2), height / 2 - offsetY + (canvas.width / 2 - height / 2));
  ctx.rotate(degree * Math.PI / 360);

  ctx.drawImage(img, -(width / 2), -(height / 2), width, height);

  var image_source = img.src
  if(image_source.includes("data:image")){
    if (border === undefined) {
      let borderEle = document.getElementById("border-width")
      border = borderEle.value
    }
    if (border > 0) {
      ctx.strokeStyle = "#FFFFFF";
      ctx.lineJoin = "round";
      ctx.lineWidth = border;
      ctx.strokeRect(-(width / 2), -(height / 2), width, height);
    }
  }

  ctx.restore();
}

const memo: { [key: string]: HTMLImageElement } = {};
function renderImage(imageSrc: string, width: number, height: number, offsetX: number, offsetY: number, degree: number, border: number) {
  return new Promise((resolve, reject) => {
    if (!ctx) {
      reject();
      return;
    }
    if (memo[imageSrc]) {
      drawImage(memo[imageSrc], width, height, offsetX, offsetY, degree, border);
      resolve(memo[imageSrc]);
    } else {
      const img = new Image();
      img.onload = function () {
        drawImage(img, width, height, offsetX, offsetY, degree, border);

        memo[imageSrc] = img;
        resolve(img);
      };
      img.src = imageSrc;
    }
  });
};

async function render(ctx: CanvasRenderingContext2D, fn: VoidFunction | null) {
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = "rgba(255, 255, 255, 0)";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  var type = document.querySelector('input[name="icon-type"]:checked').value;
  var background = document.querySelector('input[name="background-type"]:checked').value;
  var size = document.getElementById('icon-size').value;

  if (background != 0) {
    await renderImage(backgrounds[background], 1024, 1024, 0, 0, 0, -1);
  }
  await renderImage(types[type][0], size, size, 0, 0, 0, -1);
  if (fn) await fn();
  await renderImage(types[type][1], size, size/2, 0, -90, 0, -1);
};

render(ctx, null);
